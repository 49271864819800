import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _399de497 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _583d2b83 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _5a9ff2e4 = () => interopDefault(import('../pages/Academy.vue' /* webpackChunkName: "" */))
const _19ae97be = () => interopDefault(import('../pages/Academy/Academy.vue' /* webpackChunkName: "" */))
const _3edc9b99 = () => interopDefault(import('../pages/Academy/Category.vue' /* webpackChunkName: "" */))
const _51c1c119 = () => interopDefault(import('../pages/Academy/Category/Item.vue' /* webpackChunkName: "" */))
const _dc355170 = () => interopDefault(import('../pages/Application.vue' /* webpackChunkName: "" */))
const _4c0254de = () => interopDefault(import('../pages/Catalog.vue' /* webpackChunkName: "pages/Catalog" */))
const _0414617e = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _42618cca = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _bad1d83e = () => interopDefault(import('../pages/Checkout/Cart.vue' /* webpackChunkName: "" */))
const _ec202396 = () => interopDefault(import('../pages/Checkout/Delivery.vue' /* webpackChunkName: "" */))
const _1470f716 = () => interopDefault(import('../pages/Checkout/Summary.vue' /* webpackChunkName: "" */))
const _33180e96 = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1caa5731 = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "pages/Checkout/UserAccount" */))
const _0bc7b3ea = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "" */))
const _75e0805c = () => interopDefault(import('../pages/Esco.vue' /* webpackChunkName: "" */))
const _1bb7de68 = () => interopDefault(import('../pages/InternalNews.vue' /* webpackChunkName: "" */))
const _180394e1 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _3981dff1 = () => interopDefault(import('../pages/LoginAsCustomer.vue' /* webpackChunkName: "" */))
const _6991be91 = () => interopDefault(import('../pages/Members.vue' /* webpackChunkName: "" */))
const _2f748ff6 = () => interopDefault(import('../pages/Members/Dashboard.vue' /* webpackChunkName: "" */))
const _dcf7ff6a = () => interopDefault(import('../pages/Members/Articles.vue' /* webpackChunkName: "" */))
const _33246ed6 = () => interopDefault(import('../pages/Members/ChangeLog.vue' /* webpackChunkName: "" */))
const _7f294253 = () => interopDefault(import('../pages/Members/InStock.vue' /* webpackChunkName: "" */))
const _1e7ffcf3 = () => interopDefault(import('../pages/Members/Manage.vue' /* webpackChunkName: "" */))
const _9013fb1a = () => interopDefault(import('../pages/Members/Orders.vue' /* webpackChunkName: "" */))
const _7cd2fd69 = () => interopDefault(import('../pages/Members/PriceList.vue' /* webpackChunkName: "" */))
const _82c3f212 = () => interopDefault(import('../pages/Members/PriceList/Cable.vue' /* webpackChunkName: "" */))
const _1f056de2 = () => interopDefault(import('../pages/Members/ProductPhotometry.vue' /* webpackChunkName: "" */))
const _67b34801 = () => interopDefault(import('../pages/Members/TermsConditions.vue' /* webpackChunkName: "" */))
const _20ec51c3 = () => interopDefault(import('../pages/Members/MyAccount.vue' /* webpackChunkName: "" */))
const _1a8929dc = () => interopDefault(import('../pages/Members/MyAccount/AddressesDetails.vue' /* webpackChunkName: "pages/Members/MyAccount/AddressesDetails" */))
const _f1e309ce = () => interopDefault(import('../pages/Members/MyAccount/MemberApproval.vue' /* webpackChunkName: "pages/Members/MyAccount/MemberApproval" */))
const _1f2cd4a1 = () => interopDefault(import('../pages/Members/MyAccount/MyNewsletter.vue' /* webpackChunkName: "pages/Members/MyAccount/MyNewsletter" */))
const _2e0ceff1 = () => interopDefault(import('../pages/Members/MyAccount/MyProfile.vue' /* webpackChunkName: "pages/Members/MyAccount/MyProfile" */))
const _3b9f53ad = () => interopDefault(import('../pages/Members/MyAccount/MyWishlist.vue' /* webpackChunkName: "pages/Members/MyAccount/MyWishlist" */))
const _4a59a4c6 = () => interopDefault(import('../modules/vizulo/news/News.vue' /* webpackChunkName: "" */))
const _ec5a0b72 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _0041b700 = () => interopDefault(import('../pages/ProductByOrder.vue' /* webpackChunkName: "pages/ProductByOrder" */))
const _5916b488 = () => interopDefault(import('../pages/ProductConfigurator.vue' /* webpackChunkName: "pages/ProductConfigurator" */))
const _1a679520 = () => interopDefault(import('../pages/ProductConfigurator/SelectProduct.vue' /* webpackChunkName: "pages/ProductConfigurator/SelectProduct" */))
const _30c9e626 = () => interopDefault(import('../modules/vizulo/references/References.vue' /* webpackChunkName: "" */))
const _b9bbebfc = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _577cd54c = () => interopDefault(import('../modules/vizulo/vacancies/Vacancies.vue' /* webpackChunkName: "" */))
const _5caced05 = () => interopDefault(import('../modules/vizulo/optics/components/Catalog/Optics.vue' /* webpackChunkName: "" */))
const _3d657506 = () => interopDefault(import('../modules/vizulo/news/Item.vue' /* webpackChunkName: "" */))
const _f3143236 = () => interopDefault(import('../modules/catalog/pages/BaseCategory.vue' /* webpackChunkName: "" */))
const _6ea535b1 = () => interopDefault(import('../modules/vizulo/references/Item.vue' /* webpackChunkName: "" */))
const _52e761a2 = () => interopDefault(import('../modules/vizulo/vacancies/Item.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _399de497,
    name: "home___de"
  }, {
    path: "/en",
    component: _399de497,
    name: "home___en"
  }, {
    path: "/fr",
    component: _399de497,
    name: "home___fr"
  }, {
    path: "/pl",
    component: _399de497,
    name: "home___pl"
  }, {
    path: "/us",
    component: _399de497,
    name: "home___us"
  }, {
    path: "/de/about-us",
    component: _583d2b83,
    name: "about-us___de"
  }, {
    path: "/de/AboutUs",
    component: _583d2b83,
    name: "AboutUs___de"
  }, {
    path: "/de/academy",
    component: _5a9ff2e4,
    children: [{
      path: "",
      component: _19ae97be,
      name: "academy___de"
    }, {
      path: ":category",
      component: _3edc9b99,
      name: "academy-category___de"
    }, {
      path: ":category/:slug",
      component: _51c1c119,
      name: "academy-item___de"
    }]
  }, {
    path: "/de/Academy",
    component: _5a9ff2e4,
    name: "Academy___de",
    children: [{
      path: "Academy",
      component: _19ae97be,
      name: "Academy-Academy___de"
    }, {
      path: "Category",
      component: _3edc9b99,
      name: "Academy-Category___de",
      children: [{
        path: "Item",
        component: _51c1c119,
        name: "Academy-Category-Item___de"
      }]
    }]
  }, {
    path: "/de/app",
    component: _dc355170,
    name: "app___de"
  }, {
    path: "/de/Application",
    component: _dc355170,
    name: "Application___de"
  }, {
    path: "/de/Catalog",
    component: _4c0254de,
    name: "Catalog___de"
  }, {
    path: "/de/checkout",
    component: _0414617e,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _42618cca,
      name: "billing___de"
    }, {
      path: "cart",
      component: _bad1d83e,
      name: "cart___de"
    }, {
      path: "delivery",
      component: _ec202396,
      name: "delivery___de"
    }, {
      path: "summary",
      component: _1470f716,
      name: "summary___de"
    }, {
      path: "thank-you",
      component: _33180e96,
      name: "thank-you___de"
    }]
  }, {
    path: "/de/Checkout",
    component: _0414617e,
    name: "Checkout___de",
    children: [{
      path: "Billing",
      component: _42618cca,
      name: "Checkout-Billing___de"
    }, {
      path: "Cart",
      component: _bad1d83e,
      name: "Checkout-Cart___de"
    }, {
      path: "Delivery",
      component: _ec202396,
      name: "Checkout-Delivery___de"
    }, {
      path: "Summary",
      component: _1470f716,
      name: "Checkout-Summary___de"
    }, {
      path: "ThankYou",
      component: _33180e96,
      name: "Checkout-ThankYou___de"
    }, {
      path: "UserAccount",
      component: _1caa5731,
      name: "Checkout-UserAccount___de"
    }]
  }, {
    path: "/de/contacts",
    component: _0bc7b3ea,
    name: "contacts___de"
  }, {
    path: "/de/Contacts",
    component: _0bc7b3ea,
    name: "Contacts___de"
  }, {
    path: "/de/esco",
    component: _75e0805c,
    name: "esco___de"
  }, {
    path: "/de/Esco",
    component: _75e0805c,
    name: "Esco___de"
  }, {
    path: "/de/Home",
    component: _399de497,
    name: "Home___de"
  }, {
    path: "/de/internal-news",
    component: _1bb7de68,
    meta: {"robots":"noindex, nofollow"},
    name: "internal_news___de"
  }, {
    path: "/de/InternalNews",
    component: _1bb7de68,
    name: "InternalNews___de"
  }, {
    path: "/de/Login",
    component: _180394e1,
    name: "Login___de"
  }, {
    path: "/de/loginascustomer",
    component: _3981dff1,
    children: [{
      path: "",
      component: _3981dff1,
      name: "loginascustomer___de"
    }, {
      path: "login",
      component: _3981dff1,
      name: "loginascustomer_login___de"
    }, {
      path: "login/index",
      component: _3981dff1,
      name: "login_index___de"
    }]
  }, {
    path: "/de/LoginAsCustomer",
    component: _3981dff1,
    name: "LoginAsCustomer___de"
  }, {
    path: "/de/members",
    component: _6991be91,
    children: [{
      path: "",
      component: _2f748ff6,
      name: "members___de"
    }, {
      path: "articles",
      component: _dcf7ff6a,
      name: "articles___de"
    }, {
      path: "change-log",
      component: _33246ed6,
      name: "change-log___de"
    }, {
      path: "in-stock",
      component: _7f294253,
      name: "in-stock___de"
    }, {
      path: "manage",
      component: _1e7ffcf3,
      name: "manage___de"
    }, {
      path: "orders",
      component: _9013fb1a,
      name: "orders___de"
    }, {
      path: "price-list",
      component: _7cd2fd69,
      name: "price-list___de"
    }, {
      path: "price-list-cable",
      component: _82c3f212,
      name: "price-list-cable___de"
    }, {
      path: "product-photometry",
      component: _1f056de2,
      name: "product-photometry___de"
    }, {
      path: "terms-conditions",
      component: _67b34801,
      name: "terms-conditions___de"
    }, {
      path: "my-account/:pageName?",
      component: _20ec51c3,
      name: "my-account___de"
    }]
  }, {
    path: "/de/Members",
    component: _6991be91,
    name: "Members___de",
    children: [{
      path: "Articles",
      component: _dcf7ff6a,
      name: "Members-Articles___de"
    }, {
      path: "ChangeLog",
      component: _33246ed6,
      name: "Members-ChangeLog___de"
    }, {
      path: "Dashboard",
      component: _2f748ff6,
      name: "Members-Dashboard___de"
    }, {
      path: "InStock",
      component: _7f294253,
      name: "Members-InStock___de"
    }, {
      path: "Manage",
      component: _1e7ffcf3,
      name: "Members-Manage___de"
    }, {
      path: "MyAccount",
      component: _20ec51c3,
      name: "Members-MyAccount___de",
      children: [{
        path: "AddressesDetails",
        component: _1a8929dc,
        name: "Members-MyAccount-AddressesDetails___de"
      }, {
        path: "MemberApproval",
        component: _f1e309ce,
        name: "Members-MyAccount-MemberApproval___de"
      }, {
        path: "MyNewsletter",
        component: _1f2cd4a1,
        name: "Members-MyAccount-MyNewsletter___de"
      }, {
        path: "MyProfile",
        component: _2e0ceff1,
        name: "Members-MyAccount-MyProfile___de"
      }, {
        path: "MyWishlist",
        component: _3b9f53ad,
        name: "Members-MyAccount-MyWishlist___de"
      }]
    }, {
      path: "Orders",
      component: _9013fb1a,
      name: "Members-Orders___de"
    }, {
      path: "PriceList",
      component: _7cd2fd69,
      name: "Members-PriceList___de",
      children: [{
        path: "Cable",
        component: _82c3f212,
        name: "Members-PriceList-Cable___de"
      }]
    }, {
      path: "ProductPhotometry",
      component: _1f056de2,
      name: "Members-ProductPhotometry___de"
    }, {
      path: "TermsConditions",
      component: _67b34801,
      name: "Members-TermsConditions___de"
    }]
  }, {
    path: "/de/news",
    component: _4a59a4c6,
    name: "news___de"
  }, {
    path: "/de/Page",
    component: _ec5a0b72,
    name: "Page___de"
  }, {
    path: "/de/ProductByOrder",
    component: _0041b700,
    name: "ProductByOrder___de"
  }, {
    path: "/de/ProductConfigurator",
    component: _5916b488,
    name: "ProductConfigurator___de",
    children: [{
      path: "SelectProduct",
      component: _1a679520,
      name: "ProductConfigurator-SelectProduct___de"
    }]
  }, {
    path: "/de/references",
    component: _30c9e626,
    name: "references___de"
  }, {
    path: "/de/reset-password",
    component: _b9bbebfc,
    alias: "/customer/account/createPassword",
    name: "reset-password___de"
  }, {
    path: "/de/ResetPassword",
    component: _b9bbebfc,
    name: "ResetPassword___de"
  }, {
    path: "/de/vacancies",
    component: _577cd54c,
    name: "vacancies___de"
  }, {
    path: "/en/about-us",
    component: _583d2b83,
    name: "about-us___en"
  }, {
    path: "/en/AboutUs",
    component: _583d2b83,
    name: "AboutUs___en"
  }, {
    path: "/en/academy",
    component: _5a9ff2e4,
    children: [{
      path: "",
      component: _19ae97be,
      name: "academy___en"
    }, {
      path: ":category",
      component: _3edc9b99,
      name: "academy-category___en"
    }, {
      path: ":category/:slug",
      component: _51c1c119,
      name: "academy-item___en"
    }]
  }, {
    path: "/en/Academy",
    component: _5a9ff2e4,
    name: "Academy___en",
    children: [{
      path: "Academy",
      component: _19ae97be,
      name: "Academy-Academy___en"
    }, {
      path: "Category",
      component: _3edc9b99,
      name: "Academy-Category___en",
      children: [{
        path: "Item",
        component: _51c1c119,
        name: "Academy-Category-Item___en"
      }]
    }]
  }, {
    path: "/en/app",
    component: _dc355170,
    name: "app___en"
  }, {
    path: "/en/Application",
    component: _dc355170,
    name: "Application___en"
  }, {
    path: "/en/Catalog",
    component: _4c0254de,
    name: "Catalog___en"
  }, {
    path: "/en/checkout",
    component: _0414617e,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _42618cca,
      name: "billing___en"
    }, {
      path: "cart",
      component: _bad1d83e,
      name: "cart___en"
    }, {
      path: "delivery",
      component: _ec202396,
      name: "delivery___en"
    }, {
      path: "summary",
      component: _1470f716,
      name: "summary___en"
    }, {
      path: "thank-you",
      component: _33180e96,
      name: "thank-you___en"
    }]
  }, {
    path: "/en/Checkout",
    component: _0414617e,
    name: "Checkout___en",
    children: [{
      path: "Billing",
      component: _42618cca,
      name: "Checkout-Billing___en"
    }, {
      path: "Cart",
      component: _bad1d83e,
      name: "Checkout-Cart___en"
    }, {
      path: "Delivery",
      component: _ec202396,
      name: "Checkout-Delivery___en"
    }, {
      path: "Summary",
      component: _1470f716,
      name: "Checkout-Summary___en"
    }, {
      path: "ThankYou",
      component: _33180e96,
      name: "Checkout-ThankYou___en"
    }, {
      path: "UserAccount",
      component: _1caa5731,
      name: "Checkout-UserAccount___en"
    }]
  }, {
    path: "/en/contacts",
    component: _0bc7b3ea,
    name: "contacts___en"
  }, {
    path: "/en/Contacts",
    component: _0bc7b3ea,
    name: "Contacts___en"
  }, {
    path: "/en/esco",
    component: _75e0805c,
    name: "esco___en"
  }, {
    path: "/en/Esco",
    component: _75e0805c,
    name: "Esco___en"
  }, {
    path: "/en/Home",
    component: _399de497,
    name: "Home___en"
  }, {
    path: "/en/internal-news",
    component: _1bb7de68,
    meta: {"robots":"noindex, nofollow"},
    name: "internal_news___en"
  }, {
    path: "/en/InternalNews",
    component: _1bb7de68,
    name: "InternalNews___en"
  }, {
    path: "/en/Login",
    component: _180394e1,
    name: "Login___en"
  }, {
    path: "/en/loginascustomer",
    component: _3981dff1,
    children: [{
      path: "",
      component: _3981dff1,
      name: "loginascustomer___en"
    }, {
      path: "login",
      component: _3981dff1,
      name: "loginascustomer_login___en"
    }, {
      path: "login/index",
      component: _3981dff1,
      name: "login_index___en"
    }]
  }, {
    path: "/en/LoginAsCustomer",
    component: _3981dff1,
    name: "LoginAsCustomer___en"
  }, {
    path: "/en/members",
    component: _6991be91,
    children: [{
      path: "",
      component: _2f748ff6,
      name: "members___en"
    }, {
      path: "articles",
      component: _dcf7ff6a,
      name: "articles___en"
    }, {
      path: "change-log",
      component: _33246ed6,
      name: "change-log___en"
    }, {
      path: "in-stock",
      component: _7f294253,
      name: "in-stock___en"
    }, {
      path: "manage",
      component: _1e7ffcf3,
      name: "manage___en"
    }, {
      path: "orders",
      component: _9013fb1a,
      name: "orders___en"
    }, {
      path: "price-list",
      component: _7cd2fd69,
      name: "price-list___en"
    }, {
      path: "price-list-cable",
      component: _82c3f212,
      name: "price-list-cable___en"
    }, {
      path: "product-photometry",
      component: _1f056de2,
      name: "product-photometry___en"
    }, {
      path: "terms-conditions",
      component: _67b34801,
      name: "terms-conditions___en"
    }, {
      path: "my-account/:pageName?",
      component: _20ec51c3,
      name: "my-account___en"
    }]
  }, {
    path: "/en/Members",
    component: _6991be91,
    name: "Members___en",
    children: [{
      path: "Articles",
      component: _dcf7ff6a,
      name: "Members-Articles___en"
    }, {
      path: "ChangeLog",
      component: _33246ed6,
      name: "Members-ChangeLog___en"
    }, {
      path: "Dashboard",
      component: _2f748ff6,
      name: "Members-Dashboard___en"
    }, {
      path: "InStock",
      component: _7f294253,
      name: "Members-InStock___en"
    }, {
      path: "Manage",
      component: _1e7ffcf3,
      name: "Members-Manage___en"
    }, {
      path: "MyAccount",
      component: _20ec51c3,
      name: "Members-MyAccount___en",
      children: [{
        path: "AddressesDetails",
        component: _1a8929dc,
        name: "Members-MyAccount-AddressesDetails___en"
      }, {
        path: "MemberApproval",
        component: _f1e309ce,
        name: "Members-MyAccount-MemberApproval___en"
      }, {
        path: "MyNewsletter",
        component: _1f2cd4a1,
        name: "Members-MyAccount-MyNewsletter___en"
      }, {
        path: "MyProfile",
        component: _2e0ceff1,
        name: "Members-MyAccount-MyProfile___en"
      }, {
        path: "MyWishlist",
        component: _3b9f53ad,
        name: "Members-MyAccount-MyWishlist___en"
      }]
    }, {
      path: "Orders",
      component: _9013fb1a,
      name: "Members-Orders___en"
    }, {
      path: "PriceList",
      component: _7cd2fd69,
      name: "Members-PriceList___en",
      children: [{
        path: "Cable",
        component: _82c3f212,
        name: "Members-PriceList-Cable___en"
      }]
    }, {
      path: "ProductPhotometry",
      component: _1f056de2,
      name: "Members-ProductPhotometry___en"
    }, {
      path: "TermsConditions",
      component: _67b34801,
      name: "Members-TermsConditions___en"
    }]
  }, {
    path: "/en/news",
    component: _4a59a4c6,
    name: "news___en"
  }, {
    path: "/en/Page",
    component: _ec5a0b72,
    name: "Page___en"
  }, {
    path: "/en/ProductByOrder",
    component: _0041b700,
    name: "ProductByOrder___en"
  }, {
    path: "/en/ProductConfigurator",
    component: _5916b488,
    name: "ProductConfigurator___en",
    children: [{
      path: "SelectProduct",
      component: _1a679520,
      name: "ProductConfigurator-SelectProduct___en"
    }]
  }, {
    path: "/en/references",
    component: _30c9e626,
    name: "references___en"
  }, {
    path: "/en/reset-password",
    component: _b9bbebfc,
    alias: "/customer/account/createPassword",
    name: "reset-password___en"
  }, {
    path: "/en/ResetPassword",
    component: _b9bbebfc,
    name: "ResetPassword___en"
  }, {
    path: "/en/vacancies",
    component: _577cd54c,
    name: "vacancies___en"
  }, {
    path: "/fr/about-us",
    component: _583d2b83,
    name: "about-us___fr"
  }, {
    path: "/fr/AboutUs",
    component: _583d2b83,
    name: "AboutUs___fr"
  }, {
    path: "/fr/academy",
    component: _5a9ff2e4,
    children: [{
      path: "",
      component: _19ae97be,
      name: "academy___fr"
    }, {
      path: ":category",
      component: _3edc9b99,
      name: "academy-category___fr"
    }, {
      path: ":category/:slug",
      component: _51c1c119,
      name: "academy-item___fr"
    }]
  }, {
    path: "/fr/Academy",
    component: _5a9ff2e4,
    name: "Academy___fr",
    children: [{
      path: "Academy",
      component: _19ae97be,
      name: "Academy-Academy___fr"
    }, {
      path: "Category",
      component: _3edc9b99,
      name: "Academy-Category___fr",
      children: [{
        path: "Item",
        component: _51c1c119,
        name: "Academy-Category-Item___fr"
      }]
    }]
  }, {
    path: "/fr/app",
    component: _dc355170,
    name: "app___fr"
  }, {
    path: "/fr/Application",
    component: _dc355170,
    name: "Application___fr"
  }, {
    path: "/fr/Catalog",
    component: _4c0254de,
    name: "Catalog___fr"
  }, {
    path: "/fr/checkout",
    component: _0414617e,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _42618cca,
      name: "billing___fr"
    }, {
      path: "cart",
      component: _bad1d83e,
      name: "cart___fr"
    }, {
      path: "delivery",
      component: _ec202396,
      name: "delivery___fr"
    }, {
      path: "summary",
      component: _1470f716,
      name: "summary___fr"
    }, {
      path: "thank-you",
      component: _33180e96,
      name: "thank-you___fr"
    }]
  }, {
    path: "/fr/Checkout",
    component: _0414617e,
    name: "Checkout___fr",
    children: [{
      path: "Billing",
      component: _42618cca,
      name: "Checkout-Billing___fr"
    }, {
      path: "Cart",
      component: _bad1d83e,
      name: "Checkout-Cart___fr"
    }, {
      path: "Delivery",
      component: _ec202396,
      name: "Checkout-Delivery___fr"
    }, {
      path: "Summary",
      component: _1470f716,
      name: "Checkout-Summary___fr"
    }, {
      path: "ThankYou",
      component: _33180e96,
      name: "Checkout-ThankYou___fr"
    }, {
      path: "UserAccount",
      component: _1caa5731,
      name: "Checkout-UserAccount___fr"
    }]
  }, {
    path: "/fr/contacts",
    component: _0bc7b3ea,
    name: "contacts___fr"
  }, {
    path: "/fr/Contacts",
    component: _0bc7b3ea,
    name: "Contacts___fr"
  }, {
    path: "/fr/esco",
    component: _75e0805c,
    name: "esco___fr"
  }, {
    path: "/fr/Esco",
    component: _75e0805c,
    name: "Esco___fr"
  }, {
    path: "/fr/Home",
    component: _399de497,
    name: "Home___fr"
  }, {
    path: "/fr/internal-news",
    component: _1bb7de68,
    meta: {"robots":"noindex, nofollow"},
    name: "internal_news___fr"
  }, {
    path: "/fr/InternalNews",
    component: _1bb7de68,
    name: "InternalNews___fr"
  }, {
    path: "/fr/Login",
    component: _180394e1,
    name: "Login___fr"
  }, {
    path: "/fr/loginascustomer",
    component: _3981dff1,
    children: [{
      path: "",
      component: _3981dff1,
      name: "loginascustomer___fr"
    }, {
      path: "login",
      component: _3981dff1,
      name: "loginascustomer_login___fr"
    }, {
      path: "login/index",
      component: _3981dff1,
      name: "login_index___fr"
    }]
  }, {
    path: "/fr/LoginAsCustomer",
    component: _3981dff1,
    name: "LoginAsCustomer___fr"
  }, {
    path: "/fr/members",
    component: _6991be91,
    children: [{
      path: "",
      component: _2f748ff6,
      name: "members___fr"
    }, {
      path: "articles",
      component: _dcf7ff6a,
      name: "articles___fr"
    }, {
      path: "change-log",
      component: _33246ed6,
      name: "change-log___fr"
    }, {
      path: "in-stock",
      component: _7f294253,
      name: "in-stock___fr"
    }, {
      path: "manage",
      component: _1e7ffcf3,
      name: "manage___fr"
    }, {
      path: "orders",
      component: _9013fb1a,
      name: "orders___fr"
    }, {
      path: "price-list",
      component: _7cd2fd69,
      name: "price-list___fr"
    }, {
      path: "price-list-cable",
      component: _82c3f212,
      name: "price-list-cable___fr"
    }, {
      path: "product-photometry",
      component: _1f056de2,
      name: "product-photometry___fr"
    }, {
      path: "terms-conditions",
      component: _67b34801,
      name: "terms-conditions___fr"
    }, {
      path: "my-account/:pageName?",
      component: _20ec51c3,
      name: "my-account___fr"
    }]
  }, {
    path: "/fr/Members",
    component: _6991be91,
    name: "Members___fr",
    children: [{
      path: "Articles",
      component: _dcf7ff6a,
      name: "Members-Articles___fr"
    }, {
      path: "ChangeLog",
      component: _33246ed6,
      name: "Members-ChangeLog___fr"
    }, {
      path: "Dashboard",
      component: _2f748ff6,
      name: "Members-Dashboard___fr"
    }, {
      path: "InStock",
      component: _7f294253,
      name: "Members-InStock___fr"
    }, {
      path: "Manage",
      component: _1e7ffcf3,
      name: "Members-Manage___fr"
    }, {
      path: "MyAccount",
      component: _20ec51c3,
      name: "Members-MyAccount___fr",
      children: [{
        path: "AddressesDetails",
        component: _1a8929dc,
        name: "Members-MyAccount-AddressesDetails___fr"
      }, {
        path: "MemberApproval",
        component: _f1e309ce,
        name: "Members-MyAccount-MemberApproval___fr"
      }, {
        path: "MyNewsletter",
        component: _1f2cd4a1,
        name: "Members-MyAccount-MyNewsletter___fr"
      }, {
        path: "MyProfile",
        component: _2e0ceff1,
        name: "Members-MyAccount-MyProfile___fr"
      }, {
        path: "MyWishlist",
        component: _3b9f53ad,
        name: "Members-MyAccount-MyWishlist___fr"
      }]
    }, {
      path: "Orders",
      component: _9013fb1a,
      name: "Members-Orders___fr"
    }, {
      path: "PriceList",
      component: _7cd2fd69,
      name: "Members-PriceList___fr",
      children: [{
        path: "Cable",
        component: _82c3f212,
        name: "Members-PriceList-Cable___fr"
      }]
    }, {
      path: "ProductPhotometry",
      component: _1f056de2,
      name: "Members-ProductPhotometry___fr"
    }, {
      path: "TermsConditions",
      component: _67b34801,
      name: "Members-TermsConditions___fr"
    }]
  }, {
    path: "/fr/news",
    component: _4a59a4c6,
    name: "news___fr"
  }, {
    path: "/fr/Page",
    component: _ec5a0b72,
    name: "Page___fr"
  }, {
    path: "/fr/ProductByOrder",
    component: _0041b700,
    name: "ProductByOrder___fr"
  }, {
    path: "/fr/ProductConfigurator",
    component: _5916b488,
    name: "ProductConfigurator___fr",
    children: [{
      path: "SelectProduct",
      component: _1a679520,
      name: "ProductConfigurator-SelectProduct___fr"
    }]
  }, {
    path: "/fr/references",
    component: _30c9e626,
    name: "references___fr"
  }, {
    path: "/fr/reset-password",
    component: _b9bbebfc,
    alias: "/customer/account/createPassword",
    name: "reset-password___fr"
  }, {
    path: "/fr/ResetPassword",
    component: _b9bbebfc,
    name: "ResetPassword___fr"
  }, {
    path: "/fr/vacancies",
    component: _577cd54c,
    name: "vacancies___fr"
  }, {
    path: "/pl/about-us",
    component: _583d2b83,
    name: "about-us___pl"
  }, {
    path: "/pl/AboutUs",
    component: _583d2b83,
    name: "AboutUs___pl"
  }, {
    path: "/pl/academy",
    component: _5a9ff2e4,
    children: [{
      path: "",
      component: _19ae97be,
      name: "academy___pl"
    }, {
      path: ":category",
      component: _3edc9b99,
      name: "academy-category___pl"
    }, {
      path: ":category/:slug",
      component: _51c1c119,
      name: "academy-item___pl"
    }]
  }, {
    path: "/pl/Academy",
    component: _5a9ff2e4,
    name: "Academy___pl",
    children: [{
      path: "Academy",
      component: _19ae97be,
      name: "Academy-Academy___pl"
    }, {
      path: "Category",
      component: _3edc9b99,
      name: "Academy-Category___pl",
      children: [{
        path: "Item",
        component: _51c1c119,
        name: "Academy-Category-Item___pl"
      }]
    }]
  }, {
    path: "/pl/app",
    component: _dc355170,
    name: "app___pl"
  }, {
    path: "/pl/Application",
    component: _dc355170,
    name: "Application___pl"
  }, {
    path: "/pl/Catalog",
    component: _4c0254de,
    name: "Catalog___pl"
  }, {
    path: "/pl/checkout",
    component: _0414617e,
    name: "checkout___pl",
    children: [{
      path: "billing",
      component: _42618cca,
      name: "billing___pl"
    }, {
      path: "cart",
      component: _bad1d83e,
      name: "cart___pl"
    }, {
      path: "delivery",
      component: _ec202396,
      name: "delivery___pl"
    }, {
      path: "summary",
      component: _1470f716,
      name: "summary___pl"
    }, {
      path: "thank-you",
      component: _33180e96,
      name: "thank-you___pl"
    }]
  }, {
    path: "/pl/Checkout",
    component: _0414617e,
    name: "Checkout___pl",
    children: [{
      path: "Billing",
      component: _42618cca,
      name: "Checkout-Billing___pl"
    }, {
      path: "Cart",
      component: _bad1d83e,
      name: "Checkout-Cart___pl"
    }, {
      path: "Delivery",
      component: _ec202396,
      name: "Checkout-Delivery___pl"
    }, {
      path: "Summary",
      component: _1470f716,
      name: "Checkout-Summary___pl"
    }, {
      path: "ThankYou",
      component: _33180e96,
      name: "Checkout-ThankYou___pl"
    }, {
      path: "UserAccount",
      component: _1caa5731,
      name: "Checkout-UserAccount___pl"
    }]
  }, {
    path: "/pl/contacts",
    component: _0bc7b3ea,
    name: "contacts___pl"
  }, {
    path: "/pl/Contacts",
    component: _0bc7b3ea,
    name: "Contacts___pl"
  }, {
    path: "/pl/esco",
    component: _75e0805c,
    name: "esco___pl"
  }, {
    path: "/pl/Esco",
    component: _75e0805c,
    name: "Esco___pl"
  }, {
    path: "/pl/Home",
    component: _399de497,
    name: "Home___pl"
  }, {
    path: "/pl/internal-news",
    component: _1bb7de68,
    meta: {"robots":"noindex, nofollow"},
    name: "internal_news___pl"
  }, {
    path: "/pl/InternalNews",
    component: _1bb7de68,
    name: "InternalNews___pl"
  }, {
    path: "/pl/Login",
    component: _180394e1,
    name: "Login___pl"
  }, {
    path: "/pl/loginascustomer",
    component: _3981dff1,
    children: [{
      path: "",
      component: _3981dff1,
      name: "loginascustomer___pl"
    }, {
      path: "login",
      component: _3981dff1,
      name: "loginascustomer_login___pl"
    }, {
      path: "login/index",
      component: _3981dff1,
      name: "login_index___pl"
    }]
  }, {
    path: "/pl/LoginAsCustomer",
    component: _3981dff1,
    name: "LoginAsCustomer___pl"
  }, {
    path: "/pl/members",
    component: _6991be91,
    children: [{
      path: "",
      component: _2f748ff6,
      name: "members___pl"
    }, {
      path: "articles",
      component: _dcf7ff6a,
      name: "articles___pl"
    }, {
      path: "change-log",
      component: _33246ed6,
      name: "change-log___pl"
    }, {
      path: "in-stock",
      component: _7f294253,
      name: "in-stock___pl"
    }, {
      path: "manage",
      component: _1e7ffcf3,
      name: "manage___pl"
    }, {
      path: "orders",
      component: _9013fb1a,
      name: "orders___pl"
    }, {
      path: "price-list",
      component: _7cd2fd69,
      name: "price-list___pl"
    }, {
      path: "price-list-cable",
      component: _82c3f212,
      name: "price-list-cable___pl"
    }, {
      path: "product-photometry",
      component: _1f056de2,
      name: "product-photometry___pl"
    }, {
      path: "terms-conditions",
      component: _67b34801,
      name: "terms-conditions___pl"
    }, {
      path: "my-account/:pageName?",
      component: _20ec51c3,
      name: "my-account___pl"
    }]
  }, {
    path: "/pl/Members",
    component: _6991be91,
    name: "Members___pl",
    children: [{
      path: "Articles",
      component: _dcf7ff6a,
      name: "Members-Articles___pl"
    }, {
      path: "ChangeLog",
      component: _33246ed6,
      name: "Members-ChangeLog___pl"
    }, {
      path: "Dashboard",
      component: _2f748ff6,
      name: "Members-Dashboard___pl"
    }, {
      path: "InStock",
      component: _7f294253,
      name: "Members-InStock___pl"
    }, {
      path: "Manage",
      component: _1e7ffcf3,
      name: "Members-Manage___pl"
    }, {
      path: "MyAccount",
      component: _20ec51c3,
      name: "Members-MyAccount___pl",
      children: [{
        path: "AddressesDetails",
        component: _1a8929dc,
        name: "Members-MyAccount-AddressesDetails___pl"
      }, {
        path: "MemberApproval",
        component: _f1e309ce,
        name: "Members-MyAccount-MemberApproval___pl"
      }, {
        path: "MyNewsletter",
        component: _1f2cd4a1,
        name: "Members-MyAccount-MyNewsletter___pl"
      }, {
        path: "MyProfile",
        component: _2e0ceff1,
        name: "Members-MyAccount-MyProfile___pl"
      }, {
        path: "MyWishlist",
        component: _3b9f53ad,
        name: "Members-MyAccount-MyWishlist___pl"
      }]
    }, {
      path: "Orders",
      component: _9013fb1a,
      name: "Members-Orders___pl"
    }, {
      path: "PriceList",
      component: _7cd2fd69,
      name: "Members-PriceList___pl",
      children: [{
        path: "Cable",
        component: _82c3f212,
        name: "Members-PriceList-Cable___pl"
      }]
    }, {
      path: "ProductPhotometry",
      component: _1f056de2,
      name: "Members-ProductPhotometry___pl"
    }, {
      path: "TermsConditions",
      component: _67b34801,
      name: "Members-TermsConditions___pl"
    }]
  }, {
    path: "/pl/news",
    component: _4a59a4c6,
    name: "news___pl"
  }, {
    path: "/pl/Page",
    component: _ec5a0b72,
    name: "Page___pl"
  }, {
    path: "/pl/ProductByOrder",
    component: _0041b700,
    name: "ProductByOrder___pl"
  }, {
    path: "/pl/ProductConfigurator",
    component: _5916b488,
    name: "ProductConfigurator___pl",
    children: [{
      path: "SelectProduct",
      component: _1a679520,
      name: "ProductConfigurator-SelectProduct___pl"
    }]
  }, {
    path: "/pl/references",
    component: _30c9e626,
    name: "references___pl"
  }, {
    path: "/pl/reset-password",
    component: _b9bbebfc,
    alias: "/customer/account/createPassword",
    name: "reset-password___pl"
  }, {
    path: "/pl/ResetPassword",
    component: _b9bbebfc,
    name: "ResetPassword___pl"
  }, {
    path: "/pl/vacancies",
    component: _577cd54c,
    name: "vacancies___pl"
  }, {
    path: "/us/about-us",
    component: _583d2b83,
    name: "about-us___us"
  }, {
    path: "/us/AboutUs",
    component: _583d2b83,
    name: "AboutUs___us"
  }, {
    path: "/us/academy",
    component: _5a9ff2e4,
    children: [{
      path: "",
      component: _19ae97be,
      name: "academy___us"
    }, {
      path: ":category",
      component: _3edc9b99,
      name: "academy-category___us"
    }, {
      path: ":category/:slug",
      component: _51c1c119,
      name: "academy-item___us"
    }]
  }, {
    path: "/us/Academy",
    component: _5a9ff2e4,
    name: "Academy___us",
    children: [{
      path: "Academy",
      component: _19ae97be,
      name: "Academy-Academy___us"
    }, {
      path: "Category",
      component: _3edc9b99,
      name: "Academy-Category___us",
      children: [{
        path: "Item",
        component: _51c1c119,
        name: "Academy-Category-Item___us"
      }]
    }]
  }, {
    path: "/us/app",
    component: _dc355170,
    name: "app___us"
  }, {
    path: "/us/Application",
    component: _dc355170,
    name: "Application___us"
  }, {
    path: "/us/Catalog",
    component: _4c0254de,
    name: "Catalog___us"
  }, {
    path: "/us/checkout",
    component: _0414617e,
    name: "checkout___us",
    children: [{
      path: "billing",
      component: _42618cca,
      name: "billing___us"
    }, {
      path: "cart",
      component: _bad1d83e,
      name: "cart___us"
    }, {
      path: "delivery",
      component: _ec202396,
      name: "delivery___us"
    }, {
      path: "summary",
      component: _1470f716,
      name: "summary___us"
    }, {
      path: "thank-you",
      component: _33180e96,
      name: "thank-you___us"
    }]
  }, {
    path: "/us/Checkout",
    component: _0414617e,
    name: "Checkout___us",
    children: [{
      path: "Billing",
      component: _42618cca,
      name: "Checkout-Billing___us"
    }, {
      path: "Cart",
      component: _bad1d83e,
      name: "Checkout-Cart___us"
    }, {
      path: "Delivery",
      component: _ec202396,
      name: "Checkout-Delivery___us"
    }, {
      path: "Summary",
      component: _1470f716,
      name: "Checkout-Summary___us"
    }, {
      path: "ThankYou",
      component: _33180e96,
      name: "Checkout-ThankYou___us"
    }, {
      path: "UserAccount",
      component: _1caa5731,
      name: "Checkout-UserAccount___us"
    }]
  }, {
    path: "/us/contacts",
    component: _0bc7b3ea,
    name: "contacts___us"
  }, {
    path: "/us/Contacts",
    component: _0bc7b3ea,
    name: "Contacts___us"
  }, {
    path: "/us/esco",
    component: _75e0805c,
    name: "esco___us"
  }, {
    path: "/us/Esco",
    component: _75e0805c,
    name: "Esco___us"
  }, {
    path: "/us/Home",
    component: _399de497,
    name: "Home___us"
  }, {
    path: "/us/internal-news",
    component: _1bb7de68,
    meta: {"robots":"noindex, nofollow"},
    name: "internal_news___us"
  }, {
    path: "/us/InternalNews",
    component: _1bb7de68,
    name: "InternalNews___us"
  }, {
    path: "/us/Login",
    component: _180394e1,
    name: "Login___us"
  }, {
    path: "/us/loginascustomer",
    component: _3981dff1,
    children: [{
      path: "",
      component: _3981dff1,
      name: "loginascustomer___us"
    }, {
      path: "login",
      component: _3981dff1,
      name: "loginascustomer_login___us"
    }, {
      path: "login/index",
      component: _3981dff1,
      name: "login_index___us"
    }]
  }, {
    path: "/us/LoginAsCustomer",
    component: _3981dff1,
    name: "LoginAsCustomer___us"
  }, {
    path: "/us/members",
    component: _6991be91,
    children: [{
      path: "",
      component: _2f748ff6,
      name: "members___us"
    }, {
      path: "articles",
      component: _dcf7ff6a,
      name: "articles___us"
    }, {
      path: "change-log",
      component: _33246ed6,
      name: "change-log___us"
    }, {
      path: "in-stock",
      component: _7f294253,
      name: "in-stock___us"
    }, {
      path: "manage",
      component: _1e7ffcf3,
      name: "manage___us"
    }, {
      path: "orders",
      component: _9013fb1a,
      name: "orders___us"
    }, {
      path: "price-list",
      component: _7cd2fd69,
      name: "price-list___us"
    }, {
      path: "price-list-cable",
      component: _82c3f212,
      name: "price-list-cable___us"
    }, {
      path: "product-photometry",
      component: _1f056de2,
      name: "product-photometry___us"
    }, {
      path: "terms-conditions",
      component: _67b34801,
      name: "terms-conditions___us"
    }, {
      path: "my-account/:pageName?",
      component: _20ec51c3,
      name: "my-account___us"
    }]
  }, {
    path: "/us/Members",
    component: _6991be91,
    name: "Members___us",
    children: [{
      path: "Articles",
      component: _dcf7ff6a,
      name: "Members-Articles___us"
    }, {
      path: "ChangeLog",
      component: _33246ed6,
      name: "Members-ChangeLog___us"
    }, {
      path: "Dashboard",
      component: _2f748ff6,
      name: "Members-Dashboard___us"
    }, {
      path: "InStock",
      component: _7f294253,
      name: "Members-InStock___us"
    }, {
      path: "Manage",
      component: _1e7ffcf3,
      name: "Members-Manage___us"
    }, {
      path: "MyAccount",
      component: _20ec51c3,
      name: "Members-MyAccount___us",
      children: [{
        path: "AddressesDetails",
        component: _1a8929dc,
        name: "Members-MyAccount-AddressesDetails___us"
      }, {
        path: "MemberApproval",
        component: _f1e309ce,
        name: "Members-MyAccount-MemberApproval___us"
      }, {
        path: "MyNewsletter",
        component: _1f2cd4a1,
        name: "Members-MyAccount-MyNewsletter___us"
      }, {
        path: "MyProfile",
        component: _2e0ceff1,
        name: "Members-MyAccount-MyProfile___us"
      }, {
        path: "MyWishlist",
        component: _3b9f53ad,
        name: "Members-MyAccount-MyWishlist___us"
      }]
    }, {
      path: "Orders",
      component: _9013fb1a,
      name: "Members-Orders___us"
    }, {
      path: "PriceList",
      component: _7cd2fd69,
      name: "Members-PriceList___us",
      children: [{
        path: "Cable",
        component: _82c3f212,
        name: "Members-PriceList-Cable___us"
      }]
    }, {
      path: "ProductPhotometry",
      component: _1f056de2,
      name: "Members-ProductPhotometry___us"
    }, {
      path: "TermsConditions",
      component: _67b34801,
      name: "Members-TermsConditions___us"
    }]
  }, {
    path: "/us/news",
    component: _4a59a4c6,
    name: "news___us"
  }, {
    path: "/us/Page",
    component: _ec5a0b72,
    name: "Page___us"
  }, {
    path: "/us/ProductByOrder",
    component: _0041b700,
    name: "ProductByOrder___us"
  }, {
    path: "/us/ProductConfigurator",
    component: _5916b488,
    name: "ProductConfigurator___us",
    children: [{
      path: "SelectProduct",
      component: _1a679520,
      name: "ProductConfigurator-SelectProduct___us"
    }]
  }, {
    path: "/us/references",
    component: _30c9e626,
    name: "references___us"
  }, {
    path: "/us/reset-password",
    component: _b9bbebfc,
    alias: "/customer/account/createPassword",
    name: "reset-password___us"
  }, {
    path: "/us/ResetPassword",
    component: _b9bbebfc,
    name: "ResetPassword___us"
  }, {
    path: "/us/vacancies",
    component: _577cd54c,
    name: "vacancies___us"
  }, {
    path: "/de/products/optics",
    component: _5caced05,
    name: "optics___de"
  }, {
    path: "/en/products/optics",
    component: _5caced05,
    name: "optics___en"
  }, {
    path: "/fr/products/optics",
    component: _5caced05,
    name: "optics___fr"
  }, {
    path: "/pl/products/optics",
    component: _5caced05,
    name: "optics___pl"
  }, {
    path: "/us/products/optics",
    component: _5caced05,
    name: "optics___us"
  }, {
    path: "/de/product-configurator/exterior/street-and-territory",
    component: _1a679520,
    name: "product_configurator_select_product_exterior___de"
  }, {
    path: "/en/product-configurator/exterior/street-and-territory",
    component: _1a679520,
    name: "product_configurator_select_product_exterior___en"
  }, {
    path: "/fr/product-configurator/exterior/street-and-territory",
    component: _1a679520,
    name: "product_configurator_select_product_exterior___fr"
  }, {
    path: "/pl/product-configurator/exterior/street-and-territory",
    component: _1a679520,
    name: "product_configurator_select_product_exterior___pl"
  }, {
    path: "/us/product-configurator/exterior/street-and-territory",
    component: _1a679520,
    name: "product_configurator_select_product_exterior___us"
  }, {
    path: "/de/news/:slug",
    component: _3d657506,
    name: "news_item___de"
  }, {
    path: "/de/product/:article",
    component: _0041b700,
    name: "product_data_from_order___de"
  }, {
    path: "/de/products/:cat_1",
    component: _f3143236,
    name: "base_category___de"
  }, {
    path: "/de/references/:slug",
    component: _6ea535b1,
    name: "references_item___de"
  }, {
    path: "/de/vacancies/:slug",
    component: _52e761a2,
    name: "vacancy___de"
  }, {
    path: "/en/news/:slug",
    component: _3d657506,
    name: "news_item___en"
  }, {
    path: "/en/product/:article",
    component: _0041b700,
    name: "product_data_from_order___en"
  }, {
    path: "/en/products/:cat_1",
    component: _f3143236,
    name: "base_category___en"
  }, {
    path: "/en/references/:slug",
    component: _6ea535b1,
    name: "references_item___en"
  }, {
    path: "/en/vacancies/:slug",
    component: _52e761a2,
    name: "vacancy___en"
  }, {
    path: "/fr/news/:slug",
    component: _3d657506,
    name: "news_item___fr"
  }, {
    path: "/fr/product/:article",
    component: _0041b700,
    name: "product_data_from_order___fr"
  }, {
    path: "/fr/products/:cat_1",
    component: _f3143236,
    name: "base_category___fr"
  }, {
    path: "/fr/references/:slug",
    component: _6ea535b1,
    name: "references_item___fr"
  }, {
    path: "/fr/vacancies/:slug",
    component: _52e761a2,
    name: "vacancy___fr"
  }, {
    path: "/pl/news/:slug",
    component: _3d657506,
    name: "news_item___pl"
  }, {
    path: "/pl/product/:article",
    component: _0041b700,
    name: "product_data_from_order___pl"
  }, {
    path: "/pl/products/:cat_1",
    component: _f3143236,
    name: "base_category___pl"
  }, {
    path: "/pl/references/:slug",
    component: _6ea535b1,
    name: "references_item___pl"
  }, {
    path: "/pl/vacancies/:slug",
    component: _52e761a2,
    name: "vacancy___pl"
  }, {
    path: "/us/news/:slug",
    component: _3d657506,
    name: "news_item___us"
  }, {
    path: "/us/product/:article",
    component: _0041b700,
    name: "product_data_from_order___us"
  }, {
    path: "/us/products/:cat_1",
    component: _f3143236,
    name: "base_category___us"
  }, {
    path: "/us/references/:slug",
    component: _6ea535b1,
    name: "references_item___us"
  }, {
    path: "/us/vacancies/:slug",
    component: _52e761a2,
    name: "vacancy___us"
  }, {
    path: "/de/product-configurator/:category_1?/:category_2?",
    component: _1a679520,
    name: "product_configurator_select_product___de"
  }, {
    path: "/de/products/:cat_1/:catalog",
    component: _4c0254de,
    name: "prod_or_cat___de"
  }, {
    path: "/en/product-configurator/:category_1?/:category_2?",
    component: _1a679520,
    name: "product_configurator_select_product___en"
  }, {
    path: "/en/products/:cat_1/:catalog",
    component: _4c0254de,
    name: "prod_or_cat___en"
  }, {
    path: "/fr/product-configurator/:category_1?/:category_2?",
    component: _1a679520,
    name: "product_configurator_select_product___fr"
  }, {
    path: "/fr/products/:cat_1/:catalog",
    component: _4c0254de,
    name: "prod_or_cat___fr"
  }, {
    path: "/pl/product-configurator/:category_1?/:category_2?",
    component: _1a679520,
    name: "product_configurator_select_product___pl"
  }, {
    path: "/pl/products/:cat_1/:catalog",
    component: _4c0254de,
    name: "prod_or_cat___pl"
  }, {
    path: "/us/product-configurator/:category_1?/:category_2?",
    component: _1a679520,
    name: "product_configurator_select_product___us"
  }, {
    path: "/us/products/:cat_1/:catalog",
    component: _4c0254de,
    name: "prod_or_cat___us"
  }, {
    path: "/de/product-configurator/:category_1?/:category_2?/:product_name",
    component: _5916b488,
    name: "product_configurator___de"
  }, {
    path: "/en/product-configurator/:category_1?/:category_2?/:product_name",
    component: _5916b488,
    name: "product_configurator___en"
  }, {
    path: "/fr/product-configurator/:category_1?/:category_2?/:product_name",
    component: _5916b488,
    name: "product_configurator___fr"
  }, {
    path: "/pl/product-configurator/:category_1?/:category_2?/:product_name",
    component: _5916b488,
    name: "product_configurator___pl"
  }, {
    path: "/us/product-configurator/:category_1?/:category_2?/:product_name",
    component: _5916b488,
    name: "product_configurator___us"
  }, {
    path: "/de/:slug+",
    component: _ec5a0b72,
    name: "page___de"
  }, {
    path: "/en/:slug+",
    component: _ec5a0b72,
    name: "page___en"
  }, {
    path: "/fr/:slug+",
    component: _ec5a0b72,
    name: "page___fr"
  }, {
    path: "/pl/:slug+",
    component: _ec5a0b72,
    name: "page___pl"
  }, {
    path: "/us/:slug+",
    component: _ec5a0b72,
    name: "page___us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
