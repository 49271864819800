<template>
  <div class="center mb-5">
    <div class="w-100 " v-if="activeStep==='step_5'">
        <div class="text-center black-header relative-pos flex-between text-white">
            <div class="flex">
                <div @click="resetFormData" class="reset-form" v-if="activeStep !== 'step_6'">
                    <p>{{ $t('Reset') }}</p>
                </div>
                <SkeletonLoader class="small-loader mr-3" v-if="loading.attributes"/>
            </div>
            <h3>{{ $t('Step') }} 5 - {{ $t('Finishing touches') }}</h3>
            <div class="reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openConfiguratorPopup = !openConfiguratorPopup">
                <p class="text-right">{{ $t('Report issue') }}</p>
            </div>
        </div>
        <div class="container info-message" v-if="loadingConfigs">
            <p>{{$t('Searching for matching configurations. It might take a while.')}}</p>
        </div>
        <SkeletonLoader v-if="loading.steps"/>
        <div class="container" v-else>
            <div class="base-attribute">
                <div class="w-100">
                    <div class="flex-between flex-between-color w-100">
                        <div class="flex vw-10">
                            <p>{{ $t('Color') }}</p>
                            <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['color']">
								<svg-image
									class="icon hover-icon"
									icon="info"
								/>
                                <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['color']"></span>
							</div>
                        </div>
                        <div class="flex">
                            <ul class="options selected-color" v-if="!defaultColorSelected && reactiveSelectedValues.color">
                                <li
                                    class="option active-color"
                                    :style="{'background-color': reactiveSelectedValues.color.hex_code}"
                                >
                                    <span :class="{'black-text': displayBlackText(reactiveSelectedValues.color.name.toLowerCase())}">
                                        {{ reactiveSelectedValues.color.number }}
                                    </span>
                                </li>
                            </ul>
                            <ul class="options default-color" v-if="reactiveSelectedValues.color">
                                <li
                                    class="option"
                                    :class="{ 'active-color': defaultColor.id == reactiveSelectedValues.color.id}"
                                    :style="{'background-color': defaultColor.hex_code}"
                                    @click="formDataSave('color', defaultColor, true, 'default')"
                                    @mouseover="showById = defaultColor.id"
                                    @mouseout="showById = null"
                                    :key="defaultColor.id"
                                    v-for="defaultColor in reactiveProductConfigurator.defaultColors"
                                >
                                    <span :class="{'black-text': defaultColor.name.toLowerCase().includes('white') || defaultColor.name.toLowerCase().includes('light')}">{{ defaultColor.number }}</span>
                                    <div class="popup" v-if="defaultColor.id === showById">{{ defaultColor.name }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="w-100 mt-2 mb-2">
                        <div class="mr-15 mt-3 flex-end extra-colors" @click="openColors = !openColors">
                            <div>
                                {{ $t('Extra colors') }}
                            </div>
                            <div>
                                <svg-image
                                    v-if="!openColors"
                                    height="22"
                                    icon="plus"
                                    width="22"
                                />
                                <svg-image
                                    v-else
                                    height="22"
                                    icon="minus"
                                    width="22"
                                />
                            </div>
                        </div>
                        <ul class="options color" v-if="openColors && reactiveSelectedValues.color">
                            <li
                                class="option"
                                :class="{ 'active-color': colorRAL.id == reactiveSelectedValues.color.id}"
                                :style="{'background-color': colorRAL.hex_code}"
                                @click="formDataSave('color', colorRAL, true, 'extra')"
                                @mouseover="showById = colorRAL.id"
                                @mouseout="showById = null"
                                :key="colorRAL.id"
                                v-for="colorRAL in reactiveProductConfigurator.colorsRAL"
                            >
                                <span :class="{'black-text': displayBlackText(colorRAL.name.toLowerCase())}">
                                    {{ colorRAL.number }}
                                </span>
                                <div class="popup" v-if="colorRAL.id === showById">{{ colorRAL.name }}</div>
                            </li>
                            <li
                                class="option"
                                :class="{ 'active-color': color.id == reactiveSelectedValues.color.id}"
                                :style="{'background-color': color.hex_code}"
                                @click="formDataSave('color', color, true, 'extra')"
                                @mouseover="showById = color.id"
                                @mouseout="showById = null"
                                :key="color.id"
                                v-for="color in reactiveProductConfigurator.colors"
                            >
                                 <span :class="{'black-text': displayBlackText(color.name.toLowerCase())}">
                                    {{ color.number }}
                                </span>
                                <div class="popup" v-if="color.id === showById">{{ color.name }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="base-attribute" v-if="reactiveProductConfigurator.layers && selectedValues.configuratorMode !== 'basic'">
                <div class="flex vw-10">
                    <p>{{ $t('Layers') }}</p>
                    <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['layers']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['layers']"></span>
                    </div>
                </div>
                <ul class="options">
                    <li
                        class="option"
                        :class="{ active: reactiveSelectedValues.layer && layer.id == reactiveSelectedValues.layer.id}"
                        @click="formDataSave('layer', layer, true)"
                        :key="layer.id"
                        v-for="layer in reactiveProductConfigurator.layers.feature">
                        <span>{{ layer.name }}</span>
                    </li>
                </ul>
            </div>
            <div class="base-attribute" v-if="Object.keys(reactiveProductConfigurator.mountingScrewLength).length !== 0  && selectedValues.configuratorMode !== 'basic'">
                <div class="flex vw-10">
                    <p>{{ $t('Mounting screw length') }}</p>
                    <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['mounting_screw_length']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['mounting_screw_length']"></span>
                    </div>
                </div>
                <ul class="options">
                    <li
                        class="option"
                        :class="{ active: reactiveSelectedValues.mountingScrewLength && item.id == reactiveSelectedValues.mountingScrewLength.id}"
                        @click="formDataSave('mountingScrewLength', item, true)"
                        :key="item.id"
                        v-for="item in reactiveProductConfigurator.mountingScrewLength.feature">
                        <span>{{ item.name }}</span>
                    </li>
                </ul>
            </div>
            <div class="base-attribute complete-order">
                <span @click="changeActiveStep('step_6')">{{ $t('Submit') }}</span>
            </div>
        </div>
        <IssueReportPopup
            :visible="openConfiguratorPopup"
            :userComment="userComment"
            @close="openConfiguratorPopup = !openConfiguratorPopup"
            @sendReport="sendReport"
        />
    </div>
    <div
        v-if="(activeStep !== 'step_5' && activeStep !== 'step_6')"
        class="base-attribute-button"
        @click="changeActiveStep('step_5')"
        :class="{ 'base-attribute-button-close': isCloseToActiveStep, 'nextStep': isNextStep }"
    >
        <span>
            {{ $t('Step') }} 5 - {{ $t('Finishing touches') }}
        </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import {
    ref,
    computed,
} from '@nuxtjs/composition-api';
import { useVizulo } from '~/composables';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';
import dataHelpers from '@/modules/vizulo/productConfigurator/dataHelpers';

export default defineComponent({
    name: 'Step_5',
	data: () => (
		{
            showById: null
		}
	),
    components: {
        IssueReportPopup,
    },
	props: {
        activeStep: String,
        isCloseToActiveStep: Boolean,
        storeCode: String,
        loadingConfigs: false
	},
    setup(props, {emit}) {
        const {
            productConfigurator,
            updateSelectedValues,
            generateModelName,
            getProductImage,
            testLuminaireConfigsAvailability,
            resetFormData,
            createBugReportInAsana,
            getDiode,
            setLedModuleBasedOnConfig,
            selectedValues,
            loading
        } = useProductConfigurator();
        const { prioritizeConfigs, isFullModule } = dataHelpers();
        const step = 'step_5';
        const stepIndex = 5;
        const { getApiUrl, getApiVizuloStorage } = useVizulo();
        const apiUrl = getApiUrl();
        const openColors = ref(false);
        const apiVizuloStorageUrl = getApiVizuloStorage();
        const defaultColorSelected = ref(true);
        const openConfiguratorPopup = ref(false);
        const userComment = ref({});
        const inProcessOfSubmit = ref(false);

        const colorWithBlackText = ref(['white', 'crema', 'light', 'sable', 'sulfur', 'rust', 'metallic matt']);

        const reactiveProductConfigurator = computed(() => {
            return productConfigurator.value.step_5 ?? [];
        });

        const reactiveSelectedValues = computed(()=>{
            return selectedValues.value.step_5 ?? [];
        })

        const formDataSave = async (updatedAttribute, value = '', selectedByUser = false, colorType = 'none') => {
            if (colorType === 'default') {
                defaultColorSelected.value = true;
            } else if (colorType === 'extra') {
                defaultColorSelected.value = false;
            }

            updateSelectedValues(step, updatedAttribute, value, false, selectedByUser);
        };

        const changeActiveStep = (value) => {
            if (value === step) {
                if (selectedValues.value.general.filteredLuminaireConfigs.length === 0) {
                    emit('changeStep', 'step_4');
                    if (window.document.getElementById('warning-message') !== null) {
                        window.document.getElementById('warning-message').scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                } else {
                    emit('changeStep', value);
                }
            }

            if (value === 'step_6' && selectedValues.value.general.filteredLuminaireConfigs.length === 0) {
                emit('changeStep', 'step_4');
                return;
            }

            if ( value === 'step_6' ) {
                setLedModuleBasedOnConfig();
                formDataSave('color', reactiveSelectedValues.value.color);
                getDiode(apiUrl).then(() => {
                    setValidLuminaireConfig(value);
                });
            }
        };

        const displayBlackText = (colorName) => {
            for (let color in colorWithBlackText.value) {
                if (colorName.includes(colorWithBlackText.value[color])) {
                    return true;
                }
            }

            return false;
        }

        const setConfig = async (step) => {
            if (!selectedValues.value.general.chosenConfiguration) {
                emit('changeStep', ('step_4'));
            } else {
                generateModelName();
                await getProductImage(apiUrl, props.storeCode);
                emit('changeStep', step);
            }
        }

        const setValidLuminaireConfig = (step) => {
            const priorityConfigurations = getPriorityConfiguration();

            testLuminaireConfigsAvailability(priorityConfigurations[0].id, false, props.storeCode).then(()=> {
                if (selectedValues.value.general.chosenConfiguration) {
                    setConfig(step);
                    console.log(`selected configuration = ${selectedValues.value.general?.chosenConfiguration?.id}`);
                } else {
                    console.warn('2 configuration triggered = ' + priorityConfigurations[0].id);

                    if (priorityConfigurations[1]) {
                        testLuminaireConfigsAvailability(priorityConfigurations[1].id, false, props.storeCode).then(()=> {
                            if (selectedValues.value.general.chosenConfiguration) {
                                setConfig(step);
                                console.log(`selected configuration = ${selectedValues.value.general?.chosenConfiguration?.id}`);
                            } else {
                                emit('changeStep', ('step_4'));
                            }
                        })
                    } else {
                        emit('changeStep', ('step_4'));
                    }
                }
            });

            selectedValues.value.general.ledQuantity = priorityConfigurations[0].total_diodes;
            selectedValues.value.general.chosenConfiguration = priorityConfigurations[0];
            console.log(`selected configuration = ${selectedValues.value.general?.chosenConfiguration?.id}`);
        }

        const getPriorityConfiguration = () => {
            let configIdFromPhotometry = 0;
            if (selectedValues.value.photometricConfig.hasOwnProperty('luminaire_config_id')) {
                configIdFromPhotometry = selectedValues.value.photometricConfig['luminaire_config_id'];
                delete selectedValues.value.photometricConfig['luminaire_config_id'];
            }

            const prioritizedConfigs = prioritizeConfigs(selectedValues.value, configIdFromPhotometry);
            if (prioritizedConfigs.length === 0) {
                prioritizedConfigs.push(selectedValues.value.general.filteredLuminaireConfigs[0]);

                if (selectedValues.value.general.filteredLuminaireConfigs[1]) {
                    prioritizedConfigs.push(selectedValues.value.general.filteredLuminaireConfigs[1]);
                }
            }

            let priorityConfigurations = [];
            if (selectedValues.value.step_4.luminaireConfigPreference == 2) {
                for (let i = 0; i < prioritizedConfigs.length; i++) {
                    const config = prioritizedConfigs[i];
                    if (isFullModule(config.total_diodes, selectedValues.value)) {
                        priorityConfigurations.push(config);
                        break;
                    }
                }
            }

            if (priorityConfigurations.length === 0) {
                priorityConfigurations = prioritizedConfigs;
            }

            if (prioritizedConfigs[1] && priorityConfigurations.length < 2) {
                priorityConfigurations.push(prioritizedConfigs[1]);
            }

            return priorityConfigurations;
        }

        const isNextStep = computed(()=>{
			let activeStepIndex = props.activeStep.charAt(props.activeStep.length - 1);

			if (parseInt(activeStepIndex) === stepIndex - 1 && !reactiveSelectedValues.value['isComplete']) {
				return true;
			}

			return false;
        })

        const sendReport = (sentWithSubmitButton) => {
            createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
            openConfiguratorPopup.value = false;
        }

        return {
            changeActiveStep,
            formDataSave,
            generateModelName,
            testLuminaireConfigsAvailability,
            resetFormData,
            displayBlackText,
            sendReport,
            prioritizeConfigs,
            isFullModule,
            productConfigurator,
            selectedValues,
            isNextStep,
            loading,
            reactiveProductConfigurator,
            reactiveSelectedValues,
            apiVizuloStorageUrl,
            openColors,
            defaultColorSelected,
            colorWithBlackText,
            openConfiguratorPopup,
            userComment,
        };
    }
});
</script>
<style lang="scss" scoped>
.extra-colors {
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;

    div {
        margin-right: 24px;
    }

    :last-child {
        position: relative;
        top: 1px;
    }
}

.base-attribute > div{
    font-weight: 600;
    font-size: 20px;
}

.flex-between-color {
    flex-direction: row;

    @include for-tablet-max {
        flex-direction: column;
        align-items: flex-start;
    }
}

.flex-end {
    @include for-tablet-max {
        justify-content: flex-start;
    }
}

.warning-message, .info-message {
    display: flex;
    justify-content: center;
    background: rgb(255, 213, 136);
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

.info-message {
    background: rgb(255, 255, 255);
}

.options {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include for-tablet-max {
        justify-content: flex-start;
    }

    .option {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-white);
        cursor: pointer;
        font-family: var(--font-family--primary-bold);
        padding: 9px 12px;
        border: 1px solid #979797;
        transition: all ease-in 300ms;
        display: inline-block;

        span {
            white-space:nowrap;
        }

        &:focus {
            outline: none;
        }
    }

    .option {
        margin: 6px 15px 6px 0;
        border-radius: 20px;
        @media (min-width:991px) {
            &.active,
            &:hover {
                color: #fff;
                background-color: #0B0B0C;
                border: 1px solid #0B0B0C;
                text-decoration: none;
            }
            &.active-color,
            &hover {
                outline:2px solid var(--c-black);
                outline-offset: 3px;
            }

            &.active-color {
                height: fit-content;
            }
        }
    }
}

.images {
    height: 300px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .flex-column {
        border: 1px solid #c4c4c4;
        margin: 12px;
        width: 21%;
    }
}

.color {
    padding-top: 24px;
    height: 280px;
    overflow: auto;
}

.default-color, .color {
    li {
        position: relative;
    }

    .popup {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -76%);
        background: white;
        border: 1px solid var(--_c-gray-secondary);
        color: var(--c-black);
        width: 120px;
        text-align: center;
        padding: 10px 10px;
        border-radius: 10px;
        z-index: 16;
    }
}

.selected-color {
    color: var(--c-white);
}

.color, .default-color {
    color: var(--c-white);
    .option {
        &:hover {
            outline:2px solid var(--c-black);
            outline-offset: 3px;
        }
    }
}

.min-height-base {
  min-height: 30vh;
}

.cover {
    display: block;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(7, 7, 7, 0.5);
    }
}

.flex-right {
    align-items: flex-end;
}

.mr-15{
    margin-right: 15px;
    text-align: right;
}

.border {
    border: solid 1px var(--c-black) !important;
}

.black-text {
    color: var(--c-black) !important;
}

.border-top-light {
    width: 20%;
}

.complete-order {
    background-color: var(--c-lighter-gray);
    align-items: center !important;
    justify-content: center !important;

        span {
            font-size: 22px;
            color: #fff;
            background-color: #0B0B0C;
            border: 1px solid #0B0B0C;
            text-decoration: none;
            text-transform: uppercase;
            margin: 2rem 0;
            padding: 1rem 2.5rem;
            border-radius: 40px;
            cursor: pointer;
        }
}
</style>